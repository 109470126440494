<template>
  <v-footer
    class="text-center text-md-left font-weight-light"
    dark
    padless
    color="primary"
    app
    absolute
  >
    <v-row style="max-width: 100%;">
      <v-col class="mt-10" cols="12" md="3">
        <h4 class="text-sm-h6 text-md-h6 ml-md-12">
          {{ appName }}
        </h4>
      </v-col>

      <v-col class="mt-10" cols="12" md="2">
        <ul>
          <li>
            <router-link
              to="/mention"
              class="white--text text-decoration-none caption"
            >
              Mentions légales
            </router-link>
          </li>
          <li>
            <router-link
              to="/mention"
              class="white--text text-decoration-none caption"
            >
              Termes et conditions
            </router-link>
          </li>
          <li>
            <a
              class="white--text text-decoration-none caption"
              href="mailto:secretariat@urpspharmaciensgrandest.fr"
            >
              Contact
            </a>
          </li>
          <li>
            <a
              class="white--text text-decoration-none caption"
              href="tel:0645465846"
            >
              Appeler la hotline
            </a>
          </li>
          <li>
            <router-link
              to="/faq"
              class="white--text text-decoration-none caption"
            >
              Foire aux questions
            </router-link>
          </li>
        </ul>
      </v-col>

      <v-col class="mt-10" cols="12" md="2">
        <ul>
          <li class="caption">Sponsors :</li>
          <li>
            <v-icon class="align-center hidden-sm-and-down" left x-small>
              mdi-label
            </v-icon>
            <a
              href="https://www.urpspharmaciensgrandest.fr/"
              class="white--text text-decoration-none caption"
            >
              URPS Grand Est
            </a>
          </li>
          <li>
            <v-icon class="align-center hidden-sm-and-down" left x-small>
              mdi-label
            </v-icon>

            <a
              href="https://www.ars.sante.fr/"
              class="white--text text-decoration-none caption"
            >
              ARS
            </a>
          </li>
        </ul>
      </v-col>

      <v-spacer v-if="!isMobile" cols="4"></v-spacer>

      <v-col class="mt-10" cols="12" md="2">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-subtitle class="caption white--text">
              Site réalisé en partenariat
            </v-list-item-subtitle>
            <v-list-item-subtitle class="caption white--text">
              avec le Fablab by Inetum
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <a
              class="caption white--text text-decoration-none"
              href="mailto:fablab.aura@inetum.world"
            >
              fablab.aura@inetum.world
            </a>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>

    <v-col
      class="d-md-flex align-center primary darken-1 white--text py-8"
      cols="12"
    >
      <div class="caption mx-auto">
        © Tous pour la santé - {{ new Date().getFullYear() }}
      </div>
    </v-col>
  </v-footer>
</template>

<script>
import { isMobile } from 'mobile-device-detect'

export default {
  name: 'FooterBar',

  data: () => ({
    icons: ['mdi-twitter', 'mdi-linkedin'],
    appName: process.env.VUE_APP_TITLE || 'OFFICINES',
    isMobile
  })
}
</script>

<style scoped>
li {
  list-style: none;
  padding-bottom: 12px;
}
</style>
